import React, { Fragment, useState, useRef, useEffect } from 'react';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { values } from 'lodash';
import 'sanitize.css';

const text = `Remember when we developers had personal websites with taglines
  such as “making the world a better  place through code” and links to ancient
  blog articles and a three-columns section explaining our bulletproof and
  revolutionnary method and percentage bars showing our skillset and links to
  our Stack Overflow and LinkedIn profiles and to our hello@domain.com email
  adress and we were using the third person to talk about ourselves and we had a
  portfolio full of dead or lame projects and we were all “curious and
  motivated” and also proficient in dozen of languages, frameworks and
  buzzwords and we were all in on accessibility and performance?`;

const getLinesFromRef = ref => {
  if (!ref.current) {
    return null;
  }

  const { children } = ref.current;

  const lines = values(
    Array.from(children)
      .map(child => ({
        word: child.innerText,
        top: child.offsetTop,
      }))
      .reduce((acc, { word, top }) => {
        if (!word) {
          return acc;
        }

        return {
          ...acc,
          [top]: [...(acc[top] || []), word],
        };
      }, {}),
  ).map(line => line.join(' '));

  const START_LINE_HEIGHT = 1.6;
  const END_LINE_HEIGHT = 0.85;
  const delta = (START_LINE_HEIGHT - END_LINE_HEIGHT) / (lines.length - 1);

  return lines
    .map((text, index) => ({
      text,
      lineHeight: START_LINE_HEIGHT - index * delta,
    }))
    .map(({ text, lineHeight }, index) => (
      <span className="c-line" key={index} style={{ lineHeight }}>
        {text}
      </span>
    ));
};

const IndexPage = () => {
  const [lines, setLines] = useState({});
  const [innerWidth, setInnerWidth] = useState();
  const words = useRef();

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const handler = () => {
      setInnerWidth(window.innerWidth);
    };

    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  });

  useEffect(
    () => {
      setLines({ ...lines, [innerWidth]: getLinesFromRef(words) });
    },
    [innerWidth],
  );

  if (lines[innerWidth]) {
    return (
      <Layout>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />

        <p className="c-content u-appear">{lines[innerWidth]}</p>
      </Layout>
    );
  }

  return (
    <Layout>
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />

      <p ref={words} className="c-content u-appear">
        {text.split(' ').map((word, index) => (
          <Fragment key={index}>
            <span>{word}</span>{' '}
          </Fragment>
        ))}
      </p>
    </Layout>
  );
};

export default IndexPage;
